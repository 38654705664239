@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(49, 136, 191);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.spinner-blue {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(49, 136, 191);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.spinner-yellow {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(255, 180, 70);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.spinner-green {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(88, 183, 0);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.spinner-red {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(255, 0, 0);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.spinner-white-small {
    border: 4px solid rgba(255, 255, 255, 0.3);
    /* Color de fondo del spinner */
    border-top: 4px solid rgb(255, 255, 255);
    /* Color del borde superior (spinner color) */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}
.loading {
    font-weight: bold;
    display: inline-block;
    font-family: monospace;
    font-size: 30px;
    clip-path: inset(0 3ch 0 0);
    animation: l 5s steps(4) infinite;
}

.loadingNoStyle {
    display: inline-block;
    clip-path: inset(0 1ch 0 0);
    animation: l 5s steps(4) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}
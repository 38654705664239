span {
    white-space: pre-wrap;
}

h1 {
    line-height: 0.0 !important;
}

.no-space {
    white-space: pre-wrap;
    line-height: 0.5 !important;
}
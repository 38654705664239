.collapsed {
    max-height: 20px;
    overflow-y: hidden;
    transition: max-height 1.3s ease;
}

.expanded {
    max-height: 500px;
    /* Esto debe ser suficiente para cubrir el contenido completamente cuando expandido */
    overflow-y: auto;
    transition: max-height 1.3s ease;
}

.clip-collapsed {
    clip-path: inset(10 10 100% 0);
    transition: clip-path 0.3s ease;
}

.clip-expanded {
    clip-path: inset(0);
    transition: clip-path 0.3s ease;
}

.loadingNoStyle {
    display: inline-block;
    clip-path: inset(0 1.3ch 0 0);
    animation: l 2s steps(4) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0 -0.5ch 0 0);
    }
}

.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes shine {
    from {
        background-position: top left;
        /* Inicia desde la esquina superior izquierda */
    }

    to {
        background-position: top right;
        /* Termina en la esquina superior derecha */
    }
}

.shiny-button {
    background-image: linear-gradient(135deg,
            rgba(170, 50, 220, 0.6) 0%,
            rgba(170, 50, 220, 0.6) 10%,
            /* Morado normal continuado */
            rgba(255, 105, 255, 0.8) 40%,
            rgba(170, 50, 220, 0.6) 80%,
            rgba(170, 50, 220, 0.6) 100%
            /* Morado normal al final */
        );
    background-size: 400% 100%;
    animation: shine 9s linear infinite;
    color: white;
    border: none;
}

@keyframes shine {
    0% {
        background-position: 100% center;
    }

    100% {
        background-position: -300% center;
    }
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    color: gray;
    /* Color inicial del ícono cuando no está en hover */
    transition: color 0.5s ease;
    /* Transición suave para el cambio de color inicial */
}

.icon {
    animation: pulseColor 2s infinite;
}

@keyframes pulseColor {

    0%,
    100% {
        color: rgba(170, 50, 220, 0.6);
        /* Morado inicial y final */
    }

    50% {
        color: rgba(255, 105, 255, 0.8);
        /* Morado más claro en el medio */
    }
}

.raw {
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
}

.natural {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* Oculta el componente moviéndolo hacia arriba */
.hide {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path 0.3s ease-in;
}

/* Muestra el componente expandiéndolo desde arriba hacia abajo */
.show {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: clip-path 0.3s ease-out;
}
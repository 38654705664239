#big-container {
    scroll-behavior: smooth;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

/* #root { 
     transform: scale(0.75);
     transform-origin: top left;
     width: 133.33%;
     height: 133.33%;
} */
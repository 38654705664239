@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap');

:root {
  --font-inter: 'Inter', sans-serif;
  --font-lexend: 'Lexend', sans-serif;
}

body {
  font-family: var(--font-inter);
  line-height: 1.1 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-lexend);
  line-height: 1.1 !important;
}